import { createGlobalStyle } from 'styled-components';

export const GlobalStyleOverrides = createGlobalStyle`
  #__next {
    /* height: 100%; */
    position: relative;
    height: inherit;
    z-index: 0;
    /*
      This is needed to solve a weird iOS webkit bug where if z-index is present,
      it'll sometimes prevent an element from scrolling. This is obviously very
      important for this component.
      https://stackoverflow.com/questions/26176288/webkit-overflow-scrolling-touch-breaks-in-apples-ios8/26988408#26988408
      https://css-tricks.com/forums/topic/safari-for-ios-z-index-ordering-bug-while-scrolling-a-page-with-a-fixed-element/
    */
    -webkit-transform: translate3d(0, 0, 0);
  }

  @font-face {
    font-family: display;
    font-style: normal;
    font-weight: 500;
    font-named-instance: 'Medium';
    font-display: swap;
    src: local('display'), url('/assets/fonts/Shift/Shift-Medium.woff2') format('woff2'),
      url('/assets/fonts/Shift/Shift-Medium.woff') format('woff'),
      url('/assets/fonts/Shift/Shift-Medium.eot') format('embedded-opentype');
    unicode-range: U + 000-5ff;
  }


  :root {
    --focus-box-shadow: 0 0 0 3px var(--colors-blue9);
    --error-box-shadow: 0 0 0 3px var(--colors-red9);
    --form-element-focus-box-shadow: 0 0 0 1px var(--colors-blue9);
    --sideRailWidth: 230px;
    --gallery-max-width: 1210px;
  }

  /* Removes mouse focus styles */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
  }

  /* Adds default global keyboard focus state */
  .js-focus-visible [data-focus-visible-added]:not(input):not(textarea):not(button) {
    outline: 3px solid var(--colors-blue9);
  }

  /* Adds default button keyboard focus state */
  .js-focus-visible button[data-focus-visible-added] {
    box-shadow: var(--focus-box-shadow);
    outline: none;
  }

  html {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  body {
    height: inherit;
    font: 1em/1.5 sans-serif;
    font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

 
  .ReactVirtualized__Grid:focus,
  .ReactVirtualized__Grid__innerScrollContainer:focus,
  .ReactVirtualized__Grid[data-focus-visible-added],
  .ReactVirtualized__Grid__innerScrollContainer[data-focus-visible-added]  {
    outline: none !important; /*style for .js-focus-visible in line 45 is very detailed with all its :not and overrides this */
  }

  summary::-webkit-details-marker {
    color: var(--colors-grey9);
  }

`;
